<template>
	<div class="page">
		<van-nav-bar title="选择合作方式" left-arrow border fixed placeholder safe-area-inset-top @click-left="onClickLeft" />
		<div class="header text-gray">每个账号只能选择一种合作方式，且不能随意更改</div>
		<div class="mode-title">
			<div class="mode-title-box">
				<div :class="mode==0?'mode-num':'mode-num3'">
					<span v-if="mode==0">1</span>
					<van-icon name="success" size="22" v-else />
				</div>
				<div class="mode-text">选择发票提供方式</div>
			</div>
			<div class="mode-title-box">
				<div :class="mode==1?'mode-num':'mode-num2'">2</div>
				<div class="mode-text">选择主体类型</div>
			</div>
			<div class="mode-h"></div>
		</div>
		<!-- 选择发票提供方式内容 -->
		<div class="mode-choice" v-if="mode==0">
			<div class="mode-choice-box">
				<div class="mode-choice-title">我自己提供发票</div>
				<div class="mode-main text-gray" style="margin-top: 0.6rem;">需要对您已有的企业/ 个体工商户信息进行认证</div>
				<div class="text-gray mode-main" style="margin-top: 0.18rem;">发票主体需与您接下来认证的主体信息一致且支持开具“服务费”类型的增值税普通或专用发票，认证后<span class="text-orange">不允许变更</span></div>
				<div class="mode-btn" @click="onOwnInvoice">我选择自己提供发票</div>
			</div>
			<div class="mode-choice-box" style="margin-left: 0.38rem;">
				<div class="mode-choice-title2">我无法提供发票</div>
				<div class="text-gray mode-main" style="margin-top: 0.6rem;">需通过与平台合作的第三方机构签约，由第三方机构代为报税纳税和发放</div>
				<div class="text-gray mode-main" style="margin-top: 0.1rem;">每月可申请服务费额度：<span class="text-orange">10000元</span></div>
				<div class="text-gray mode-main" style="margin-top: 0.1rem;">将会按每月申请总额的<span class="text-orange">7%收取综合服务费</span></div>
				<div class="mode-btn" @click="onChoiceMain(2)">我无法提供发票</div>
			</div>
		</div>
		<!-- 选择主体类型内容 -->
		<div class="subject" v-if="mode==1">
			<div class="subject-box">
				<div class="subject-header">
					<div class="subject-header-left">我是企业</div>
					<div class="subject-header-rigth bg-orange" @click="onChoiceMain(0)">选择</div>
				</div>
				<div class="flex padding">
					<div class="subject-vertical bg-orange"></div>
					<div class="subject-vertical-right">
						<div>每月可申请服务费额度：不限</div>
						<div class="mt">综合手续费：无</div>
						<div class="mt">需提交您的企业信息，等待2～3个工作日平台审核通过</div>
					</div>
				</div>
			</div>
			<div class="subject-box" style="margin-top: 0.6rem;">
				<div class="subject-header">
					<div class="subject-header-left2">我是个体户</div>
					<div class="subject-header-rigth bg-green" @click="onChoiceMain(1)">选择</div>
				</div>
				<div class="flex padding">
					<div class="subject-vertical bg-green"></div>
					<div class="subject-vertical-right">
						<div>每月可申请服务费额度：不限</div>
						<div class="mt">综合手续费：无</div>
						<div class="mt">需提交您的个体户信息，等待2～3个工作日平台审核通过</div>
					</div>
				</div>
				
			</div>
		</div>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				mode: 0, //0==发票提供方式 1==主体类型
			}
		},
		methods: {
			//返回
			onClickLeft() {
				console.log(window.history.length)
				if (window.history.length <= 1) {
					this.$router.push({
						path: '/'
					})
					return false
				} else {
					this.$router.go(-1)
				}
			},
			//我选择自己提供发票
			onOwnInvoice(){
				this.mode=1
			},
			//选择主体
			onChoiceMain(e){
				this.$router.push({
					path:"/realname/cooperation/explain",
					query:{
						choice:e
					}
				})
			},

		},
		created(){
			this.openid = this.$store.state.openid
		}
	}
</script>

<style scoped="scoped">
	.mt{
		margin-top: 0.28rem;
	}
	.subject-vertical-right{
		margin-left: 0.22rem;
		font-size: 0.26rem;
		color: #B2BAC9;
		margin-top: 0.1rem;
	}
	.padding{
		padding: 0.3rem;
	}
	.subject-vertical{
		width:0.06rem;
		height: 0.9rem;
	}
	.flex{
		display: flex;
	}
	.bg-green{
		background-color: #2CC09C;
	}
	.bg-orange{
		background-color: #F15223;
	}
	.subject-header-rigth{
		width: 1rem;
		/* height: 0.56rem; */
		/* line-height: 0.56rem; */
		font-size: 0.26rem;
		color: #FFFFFF;
		border-radius: 0.08rem;
		text-align: center;
		margin-right: 0.4rem;
		/* box-sizing: border-box; */
		padding: .08rem 0;
		line-height: normal
	}
	.subject-header-left2{
		font-size: 0.28rem;
		color: #2CC09C;
	}
	.subject-header-left{
		font-size: 0.28rem;
		color: #F26950;
	}
	.subject-header{
		width: 5.76rem;
		height: 1rem;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 0.02rem solid #e7e9ed;
	}
	.subject-box{
		width: 6.42rem;
		border-radius: 0.3rem;
		background-color: #FFFFFF;
	}
	.subject{
		padding: .50rem;
	}
	.mode-btn{
		width: 2.64rem;
		height: 0.6rem;
		line-height: 0.6rem;
		font-size: 0.24rem;
		background-color: #F15223;
		color: #FFFFFF;
		text-align: center;
		border-radius: 9999999px;
		margin: 0 auto;
		margin-top: 0.6rem;
	}
	.text-orange{
		color: #ff9aa2;
	}
	.mode-main{
		padding: 0 0.26rem;
		font-size: 0.26rem;
	}
	.text-gray{
		color: #B2BAC9;
	}
	.mode-choice-title2{
		font-size: 0.28rem;
		color: #2CC09C;
		padding-top: 0.5rem;
		padding-left: 0.26rem;
	}
	.mode-choice-title{
		font-size: 0.28rem;
		color: #F26950;
		padding-top: 0.5rem;
		padding-left: 0.26rem;
	}
	.mode-choice-box{
		width: 3.2rem;
		height: 6.18rem;
		border-radius: 0.2rem;
		background-color: #FFFFFF;
	}
	.mode-choice {
		display: flex;
		justify-content: center;
		padding-top: 0.66rem;
	}

	.mode-h {
		width: 3.34rem;
		height: 0.02rem;
		background-color: #F0F0F0;
		position: absolute;
		top: 0.58rem;
		left: calc(50% - 1.67rem);
	}

	.mode-text {
		font-size: 0.24rem;
		color: #000000;
		text-align: center;
		margin-top: 0.16rem;
	}
	.mode-num3 {
		width: 0.52rem;
		height: 0.52rem;
		background-color: #ffd193;
		border: 0.06rem solid #ffd193;
		border-radius: 50%;
		margin: 0 auto;
		margin-top: 0.28rem;
		line-height: 0.52rem;
		color: #FFFFFF;
		font-size: 0.2rem;
		text-align: center;
	}
	.mode-num2 {
		width: 0.52rem;
		height: 0.52rem;
		background-color: #ececec;
		border: 0.06rem solid #ececec;
		border-radius: 50%;
		margin: 0 auto;
		margin-top: 0.28rem;
		line-height: 0.52rem;
		color: #FFFFFF;
		font-size: 0.2rem;
		text-align: center;
	}

	.mode-num {
		width: 0.52rem;
		height: 0.52rem;
		background-color: #ffd193;
		border: 0.06rem solid #ffefd9;
		border-radius: 50%;
		margin: 0 auto;
		margin-top: 0.28rem;
		line-height: 0.52rem;
		color: #FFFFFF;
		font-size: 0.2rem;
		text-align: center;
	}

	.page {
		width: 100%;
		height: 100%;
		background: #f6f6f6;
	}

	.header {
		width: 100%;
		height: 0.9rem;
		line-height: 0.9rem;
		background-color: #FFF7F7;
		font-size: 0.26rem;
		color: #d7b6b9;
		text-align: center;
	}

	.mode-title {
		width: 100%;
		height: 1.72rem;
		background-color: #FFFFFF;
		display: flex;
		justify-content: space-between;
		position: relative;

	}

	.mode-title-box {
		width: 2.9rem;
		height: 1.72rem;
	}
</style>
